img {
  object-fit: contain;
  border-radius: 50%;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.bio {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
  margin: 1%;
}

body::-webkit-scrollbar {
  overflow: hidden;
  z-index: 1;
}

a {
  text-decoration: none;
}

.imageContainer img {
  height: 25rem;
  width: 100%;
}

.textContainer {
  width: 50%;
  display: felx;
  text-align: justify;
  padding-top: 5%;
  padding-left: 1%;
}
.maincontainer {
  width: 90%;
  padding-left: 5%;
}

@media (max-width: 420px) {
  .imageContainer {
    display: flex;
    justify-content: center;
  }
  .imageContainer img {
    height: 15%;
    width: 80%;
  }

  .bio {
    display: flex;
    flex-direction: column;
  }
  .textContainer {
    width: 100%;
    padding-top: 0%;
  }
}
